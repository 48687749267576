// function loadingHome() {
//   const isMobile = $(window).width() < 1025;
//   const pageScroll = $(window).scrollTop();
//   const hidePresentationButton = $('.hide-presentation');
//   const loadingTags = $('.loading');
//   const presentationTag = $('.presentation-video');

//   if (isMobile) {
//     loadingTags.removeClass('loading');
//     presentationTag.addClass('hidden');
//   }

//   if (pageScroll > 0) {
//     loadingTags.removeClass('loading');
//     presentationTag.addClass('hidden');
//   }

//   hidePresentationButton.on('click', () => {
//     loadingTags.removeClass('loading');
//     presentationTag.addClass('hidden');
//   });

// }

function menuScrollingColor() {
  const navbar = $('.navbar');

  $(window).on('scroll', () => {
    const offsetTop = $(window).scrollTop();

    if (offsetTop >= 100) {
      navbar.addClass('scrolling');
    } else {
      navbar.removeClass('scrolling');
    }
  });
}

function openMenuMobile() {
  const button = $('#header-mobile .burguer');
  const navbar = $('.navbar');
  const menuSection = $('#header-mobile .menu-mobile');

  button.on('click', (event) => {
    event.preventDefault();
    button.toggleClass('open');
    navbar.toggleClass('menu-opened');
    menuSection.toggleClass('open');
  });
}

function openMenu() {
  const button = $('#header .burguer');
  const menuSection = $('header .menu');

  button.on('click', function () {
    button.toggleClass('open');
    menuSection.toggleClass('opened');
  });

  menuSection.on('mouseleave', () => {
    menuSection.removeClass('opened');
    button.removeClass('open');
  })
}

function menuHover() {
  const imoveisArea = $('.cardslist--imoveis, #open-imoveis-section');
  const lancamentosArea = $('.cardslist--lancamentos, #open-lancamentos-section');
  const constructionArea = $('.cardslist--in-construction, #open-construction-section');
  const navbarImoveis = $('.cardslist--imoveis');
  const navbarLancamento = $('.cardslist--lancamentos');
  const navbarConstruction = $('.cardslist--in-construction');
  const menuSection = $('.menu');
  const menuButton = $('.burguer');

  imoveisArea.on('mouseenter', function (event) {
    event.preventDefault();
    navbarImoveis.addClass('opened');
    menuSection.removeClass('opened');
    menuButton.removeClass('open');
  });

  imoveisArea.on('mouseleave', () => {
    navbarImoveis.removeClass('opened');
  });

  lancamentosArea.on('mouseenter', function (event) {
    event.preventDefault();
    navbarLancamento.addClass('opened');
    menuSection.removeClass('opened');
    menuButton.removeClass('open');
  });

  lancamentosArea.on('mouseleave', () => {
    navbarLancamento.removeClass('opened');
  });

  constructionArea.on('mouseenter', function (event) {
    event.preventDefault();
    navbarConstruction.addClass('opened');
    menuSection.removeClass('opened');
    menuButton.removeClass('open');
  });

  constructionArea.on('mouseleave', () => {
    navbarConstruction.removeClass('opened');
  });
}

function menuCarousel() {
  const slider = $('#imoveis-slider, #lancamentos-slider, #in-construction-slider');

  slider.slick({
    lazyLoad: 'ondemand',
    slide: '.card-empreendimento',
    rows: 0,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: true,
    autoplay: false,
    arrows: true,
    prevArrow: '<button class="slick-prev"><i class="icon icon--arrow-left"></i></button>',
    nextArrow: '<button class="slick-next"><i class="icon icon--arrow-right"></i></button>',
  });
}

function homeEnterpriseSliderImgs() {
  const carousel = $('.carousel-enterprise-imgs');

  carousel.slick({
    lazyLoad: 'ondemand',
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    infinite: false,
    draggable: false,
    rows: 0,
    prevArrow: '<button class="slick-prev"><i class="icon icon--chevron-left"></i></button>',
    nextArrow: '<button class="slick-next"><i class="icon icon--chevron-right"></i></button>',
  });
}

function initHomeCarousel() {  
  const carousel = $('.carousel');

  carousel.slick({
    lazyLoad: 'ondemand',
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    rows: 0,
    infinite: false,
    prevArrow: '<button class="slick-prev"><i class="icon icon--arrow-left"></i></button>',
    nextArrow: '<button class="slick-next"><i class="icon icon--arrow-right"></i></button>',
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '20px',
          centerMode: true,
          infinite: true,
          arrows: false,
        }
      }
    ]
  });
}

function smoothScroll(){
  const link = $('[smooth-scroll]');

  link.on('click', (event) => {
    const target = $(event.target).attr('href');

    $('html, body').animate({
      scrollTop: $(target).offset().top - 120
    }, 500);
  });
}

function galeriaInterna() {  
  const carousel = $('.gallery-carousel');

  carousel.slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '40px',
    rows: 0,
    arrows: true,
    prevArrow: '<button class="slick-arrow slick-prev"><i class="icon icon--arrow-left-circle"></i></button>',
    nextArrow: '<button class="slick-arrow slick-next"><i class="icon icon--arrow-right-circle"></i></button>',
    responsive: [{
      breakpoint: 1024,
      settings: {
        centerMode: true,
        infinite: true,
        arrows: false,
        centerPadding: '20px',
      }
    }]
  });
}

function galeriaFloorplan() {  
  const carousel = $('.floorplan-carousel');

  carousel.slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: '40px',
    rows: 0,
    arrows: true,
    prevArrow: '<button class="slick-arrow slick-prev"><i class="icon icon--arrow-left-circle"></i></button>',
    nextArrow: '<button class="slick-arrow slick-next"><i class="icon icon--arrow-right-circle"></i></button>',
    responsive: [{
      breakpoint: 1024,
      settings: {
        centerMode: true,
        infinite: true,
        arrows: false,
        centerPadding: '20px',
      }
    }]
  });
}

function galeriaImoveis() {  
  const carousel = $('.residencial_images');

  carousel.slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    rows: 0,
    prevArrow: '<button class="slick-prev"><i class="icon icon--chevron-left"></i></button>',
    nextArrow: '<button class="slick-next"><i class="icon icon--chevron-right"></i></button>',
  });
}

function expandEnterprisesFilters() {
  const button = $('.filters_expand_button');
  const expandedArea = $('.expanded_filters');

  button.on('click', (event) => {
    event.preventDefault();
    expandedArea.toggleClass('open');

    if (expandedArea.hasClass('open')) {
      button.text('Mostrar menos');
    } else {
      button.text('Busca avançada');
    }
  });
}

function expandEnterprisesFiltersMobile() {
  const button = $('.open_filters-mobile');
  const expandedArea = $('.filters');

  button.on('click', (event) => {
    event.preventDefault();
    expandedArea.toggleClass('open');
    button.find('.icon').toggleClass('icon--chevron-down icon--close');
  });
}

function clearEnterpriseFilters() {
  const button = $('.filters_reset_button');
  const selects = $('.content-imoveis .filters select');
  const input = $('input[name="imovel-codigo"]');

  button.on('click', () => {
    input.attr('value', '');
    input.val('');

    selects.each((index, element) => {
      $(element).find('[selected]').removeAttr('selected');
      $(element).val('').change();
    });
  });
}

function previewImagesAnuncieForm() {
  const inputFile = $('#input-fotos');
  const imagesBox = $('fieldset.photos .photos-list .photo-box');

  inputFile.on('change', function () {
    const file = inputFile.get(0);

    if (file.files) {
      const filesAmount = file.files.length;

      for (let i = 0; i < filesAmount; i++) {
        const reader = new FileReader();

        reader.onload = function(event) {
          console.log('photo-box', imagesBox[i]);
          $(imagesBox[i]).css({ 'background-image': `url(${event.target.result})` });
        }

        reader.readAsDataURL(file.files[i]);
      }
    }
  });
}

function initializeFancybox() {
  Fancybox.bind("[data-fancybox]", {
    // Your custom options
  });
}

// jQuery(document).on('ready', () => {
// loadingHome();
menuScrollingColor();
openMenuMobile();
openMenu();
menuHover();
menuCarousel();
homeEnterpriseSliderImgs();
initHomeCarousel();
smoothScroll();
galeriaInterna();
galeriaFloorplan();
galeriaImoveis();
expandEnterprisesFilters();
expandEnterprisesFiltersMobile();
clearEnterpriseFilters();
previewImagesAnuncieForm();
initializeFancybox();
// });